import React, { useEffect, useState, createRef, useRef } from "react";
import PropTypes from "prop-types";
import { Input, IconButton, Spin, notification } from "antd";
import { EditOutlined } from "@ant-design/icons"; // Import EditOutlined icon
import Button from "@material-ui/core/Button";
import { Cancel } from "@material-ui/icons";

import { getDimensions } from "components/Calendar/utils";
import CircularProgress from "@material-ui/core/CircularProgress";
// import { EditOutlined, SaveOutlined, CancelOutlined } from "@material-ui/icons"; // Import Cancel icon

// import Grid from "@material-ui/core/Grid";
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
} from "@material-ui/core";

import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
// import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { updateAppointmentsData } from "apis";
import { SaveOutlined } from "@ant-design/icons";
import MuiAlert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    borderLeftWidth: 4,
    borderLeftStyle: "solid",
    borderLeftColor: ({ Cancel }) =>
      Cancel ? "#00BEB5" : theme.palette.primary.main,
    paddingLeft: theme.spacing(1),
    "& .MuiTypography-body1": {
      color: ({ Cancel }) => (Cancel ? "#606060" : "inherit"),
    },
  },
  button: {},
  leftContainer: {
    paddingRight: theme.spacing(2),
  },
  typography: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  fontWeightBold: {
    fontWeight: "bold",
  },
  buttonStyle: {
    width: "100%",
    padding: "0.5rem",
    color: "#ffffff",
  },
  pin: {
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
  },
}));

function Form({ src, title, iFrameId, appointment }) {
  console.log(appointment, "appointment");
  const classes = useStyles({ Cancel });
  const formRef = createRef();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [loading, setLoading] = useState(true);
  const weekGridCellRef = useRef(null);
  const [PhoneNumber, setPhoneNumber] = useState("");
  const [editPhone, setEditPhone] = useState(false);
  const [EmailId, setEmailId] = useState("");
  const [PatientEMRNumberData, setPatientEMRNumberData] = useState("");
  const [editEmailId, setEditEmailId] = useState(false);
  const [ZipCodeData, setZipCodeData] = useState();
  const [editZipCode, setEditZipCode] = useState(false);
  const [FirstNameValue, setFirstNameValue] = useState("");
  const [LastNameValue, setLastNameValue] = useState("");
  const phoneInputRef = useRef(null);
  const [isEditing, setIsEditing] = useState(false); // State to track if in edit mode
  const [isLoading, setIsLoading] = useState(false); // Loading state for the update button
  const [toastOpen, setToastOpen] = useState(false); // Toast notification state
  const [toastType, setToastType] = useState("success"); // Toast type state ('success' or 'error')
  const [toastMessage, setToastMessage] = useState(""); // Toast message state
  const editPhoneFun = () => {
    setEditPhone(true);
  };
  const emailEditFun = () => {
    setEditEmailId(true);
  };
  const zipCodeFun = () => {
    setEditZipCode(true);
  };
  const handleOnBlur = () => {
    console.log("blurtriggered");
    // setEditPhone(false);
    // setEditEmailId(false);
    // setEditZipCode(false);
  };
  useEffect(() => {
    if (appointment?.AppointmentID) {
      console.log("Appointment");
      setPhoneNumber(appointment.Phone);
      setEmailId(appointment.Email);
      setZipCodeData(appointment.ZipCode);
      setPatientEMRNumberData(appointment.PatientEMRNumber);
      setFirstNameValue(appointment.FirstName);
      setLastNameValue(appointment.LastName);
    }
  }, []);
  const hideSpinner = () => {
    setLoading(false);
  };
  useEffect(() => {
    if (!Cancel || !weekGridCellRef.current) return;
    const currentWeekCell = weekGridCellRef.current;
    const parentContainerCell = currentWeekCell.parentElement.parentElement;
    parentContainerCell.classList.add("cancel");
  }, [weekGridCellRef, Cancel]);
  console.log(appointment);
  const startTime = appointment?.AppointmentStartTime;
  const startApptTime = startTime?.split(" ")?.slice(-2)?.join(" ");
  useEffect(() => {
    const { width, height } = getDimensions(formRef.current);
    setDimensions({ width, height });
    var ifr = document.getElementById(iFrameId);
    if (window.location.href && window.location.href.indexOf("?") > -1) {
      var get = window.location.href.substr(
        window.location.href.indexOf("?") + 1
      );
      if (ifr && get.length > 0) {
        var src = ifr.src;
        src = src.indexOf("?") > -1 ? src + "&" + get : src + "?" + get;
        ifr.src = src;
      }
    }
    window.handleIFrameMessage = function (e) {
      if (typeof e.data === "object") {
        return;
      }
      var args = e.data.split(":");
      var iframe;
      args[2] = iFrameId.split("-")[1];
      if (args.length > 2) {
        iframe = document.getElementById(
          "JotFormIFrame-" + args[args.length - 1]
        );
      } else {
        iframe = document.getElementById("JotFormIFrame");
      }
      if (!iframe) {
        return;
      }
      switch (args[0]) {
        case "scrollIntoView":
          iframe.scrollIntoView();
          break;
        case "setHeight":
          iframe.style.height = args[1] + "px";
          break;
        case "collapseErrorPage":
          if (iframe.clientHeight > window.innerHeight) {
            iframe.style.height = window.innerHeight + "px";
          }
          break;
        case "reloadPage":
          window.location.reload();
          break;
        case "loadScript":
          var src = args[1];
          if (args.length > 3) {
            src = args[1] + ":" + args[2];
          }
          var script = document.createElement("script");
          script.src = src;
          script.type = "text/javascript";
          document.body.appendChild(script);
          break;
        case "exitFullscreen":
          if (window.document.exitFullscreen) window.document.exitFullscreen();
          else if (window.document.mozCancelFullScreen)
            window.document.mozCancelFullScreen();
          else if (window.document.mozCancelFullscreen)
            window.document.mozCancelFullScreen();
          else if (window.document.webkitExitFullscreen)
            window.document.webkitExitFullscreen();
          else if (window.document.msExitFullscreen)
            window.document.msExitFullscreen();
          break;
      }
      var isJotForm = e.origin.indexOf("jotform") > -1 ? true : false;
      if (
        isJotForm &&
        "contentWindow" in iframe &&
        "postMessage" in iframe.contentWindow
      ) {
        var urls = {
          docurl: encodeURIComponent(document.URL),
          referrer: encodeURIComponent(document.referrer),
        };
        iframe.contentWindow.postMessage(
          JSON.stringify({ type: "urls", value: urls }),
          "*"
        );
      }
    };
    if (window.addEventListener) {
      window.addEventListener("message", window.handleIFrameMessage, false);
    } else if (window.attachEvent) {
      window.attachEvent("onmessage", window.handleIFrameMessage);
    }
  }, []);
  const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };
  const editEnable = () => {
    setIsEditing(true); // Set to editing mode
    setEditPhone(true);
    setEditEmailId(true);
    setEditZipCode(true);
  };
  const handleCancel = () => {
    setEditPhone(false);
    setEditEmailId(false);
    setEditZipCode(false);
    setIsEditing(false); // Cancel editing and go back to initial state
  };
  const handleToastClose = () => {
    setToastOpen(false); // Close toast
  };
  const updateAppointmentDetails = async () => {
    setIsLoading(true); // Set loading state to true
    let updatedDetails = {
      AppointmentID: appointment?.AppointmentID,
      FirstName: FirstNameValue,
      LastName: LastNameValue,
      Email: EmailId,
      Phone: PhoneNumber,
      ZipCode: ZipCodeData,
      PatientEMRNumber: PatientEMRNumberData,
    };
    console.log(updatedDetails, " ");
    try {
      const response = await updateAppointmentsData(updatedDetails); // API call
      // console.log(response.data, "reponse....");
      if (response.status === 200) {
        const { Phone, Email, ZipCode, PatientEMRNumber, FirstName, LastName } =
          response.data.data;
        console.log(response.data.data, "reponse....");
        setPhoneNumber(Phone);
        setEmailId(Email);
        setZipCodeData(ZipCode);
        setPatientEMRNumberData(PatientEMRNumber);
        setFirstNameValue(FirstName);
        setLastNameValue(LastName);

        setToastType("success"); // Set toast type to success
        setToastMessage("Patient details updated successfully!"); // Success message
        setToastOpen(true);
        setIsEditing(false); // Reset to non-editing mode after successful update
        setIsLoading(false);
        setEditPhone(false);
        setEditEmailId(false);
        setEditZipCode(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      setToastType("error"); // Set toast type to error
      setToastMessage("Failed to update patient details. Please try again."); // Error message
      setToastOpen(true); // Show error notification
    } finally {
      setIsLoading(false); // Set loading state back to false after API call
    }
  };
  useEffect(() => {
    if (editPhone && phoneInputRef.current) {
      phoneInputRef.current.focus();
    }
  }, [editPhone]);
  return (
    <>
      {loading ? (
        <div
          style={{
            position: "absolute",
            top: "65%",
            left: "50%",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress color="primary" size={50} />
        </div>
      ) : null}
      {appointment ? (
        <>
          {" "}
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <Typography
                style={{ margin: "10px", marginLeft: "107px" }}
                className={`${classes.typography} ${classes.fontWeightBold}`}
              >
                Patient Details
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              style={{ display: "flex", gap: "16px", padding: "5px" }}
            >
              {/* Edit/Save Button */}
              <Button
                variant="contained"
                color="primary"
                onClick={isEditing ? updateAppointmentDetails : editEnable} // Toggle between edit and save actions
                disabled={isLoading} // Disable button while loading
                endIcon={isEditing ? <SaveOutlined /> : <EditOutlined />}
                style={{ position: "relative", overflow: "hidden" }} // Ensure no overflow
              >
                {isLoading ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <CircularProgress size={24} color="primary" />
                  </div>
                ) : isEditing ? (
                  "Save"
                ) : (
                  "Edit"
                )}
              </Button>

              {/* Cancel Button */}
              {isEditing && (
                <Button
                  onClick={handleCancel}
                  variant="contained"
                  color="secondary"
                  disabled={isLoading} // Disable button while loading
                  // endIcon={<Cancel />}
                >
                  Cancel
                </Button>
              )}
            </Grid>
            <Snackbar
              open={toastOpen}
              autoHideDuration={3000}
              onClose={handleToastClose}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Alert onClose={handleToastClose} severity={toastType}>
                {toastMessage}
              </Alert>
            </Snackbar>
          </Grid>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center" // Center content horizontally
            ref={weekGridCellRef}
            style={{
              display: "flex",
              flex: 1,
              alignContent: "center", // This centers content within each flex item
              alignItems: "center", // Center content vertically
              width: "78%",
              padding: "10px",
              margin: "0 auto", // Center the grid itself within its parent
              marginBottom: "40px",
              boxShadow: "0 0 8px 2px rgba(0, 0, 0, 0.15)",
              // boxSizing: "border-box",
              position: "relative",
            }}
          >
            <Grid container wrap="wrap" item xs={2}>
              <Grid item>
                <Typography className={classes.typography}>
                  {startApptTime}
                  {" - "}
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.typography}>
                  {appointment?.AppointmentEndTime}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              className={`${classes.leftContainer} ${classes.root}`}
              item
              xs={5}
              container
              direction="column"
              // wrap="nowrap"
            >
              {editPhone == true ? (
                <div
                  style={{ display: "flex", gap: "16px", marginBottom: "8px" }}
                >
                  <Input
                    ref={phoneInputRef}
                    disabled={!editPhone}
                    value={FirstNameValue}
                    onBlur={handleOnBlur}
                    onChange={(e) => {
                      console.log(e.target.value);
                      setFirstNameValue(e.target.value);
                    }}
                  />
                  <Input
                    value={LastNameValue}
                    autoFocus
                    onBlur={handleOnBlur}
                    onChange={(e) => {
                      console.log(e.target.value);
                      setLastNameValue(e.target.value);
                    }}
                  />
                </div>
              ) : (
                <Typography
                  className={`${classes.typography} ${classes.fontWeightBold}`}
                >
                  {`${FirstNameValue || appointment?.FirstName} ${
                    LastNameValue || appointment?.LastName
                  }`}
                </Typography>
              )}

              {editPhone == true ? (
                <Input
                  style={{ marginBottom: "8px" }}
                  value={PhoneNumber}
                  autoFocus
                  onBlur={handleOnBlur}
                  onChange={(e) => {
                    console.log(e.target.value);
                    setPhoneNumber(e.target.value);
                  }}
                />
              ) : (
                <Typography
                  style={{ cursor: "pointer" }}
                  className={classes.typography}
                >
                  {PhoneNumber || appointment?.Phone}
                </Typography>
              )}
              {editEmailId == true ? (
                <Input
                  style={{ marginBottom: "8px" }}
                  value={EmailId}
                  autoFocus
                  onBlur={handleOnBlur}
                  onChange={(e) => {
                    console.log(e.target.value);
                    setEmailId(e.target.value);
                  }}
                />
              ) : (
                <Typography
                  style={{ cursor: "pointer" }}
                  className={classes.typography}
                >
                  {EmailId || appointment?.Email}
                </Typography>
              )}
              <Grid wrap="wrap">
                <Typography
                  component={"span"}
                  className={classes.fontWeightBold}
                >
                  EMR&nbsp;ID:&nbsp;
                </Typography>
                {editPhone == true ? (
                  <Input
                    style={{ width: "208px" }}
                    value={PatientEMRNumberData}
                    autoFocus
                    onBlur={handleOnBlur}
                    onChange={(e) => {
                      console.log(e.target.value);
                      setPatientEMRNumberData(e.target.value);
                    }}
                  />
                ) : (
                  <Typography component={"span"}>
                    {PatientEMRNumberData || appointment?.PatientEMRNumber}
                  </Typography>
                )}
              </Grid>
              <Grid wrap="wrap">
                <Typography
                  component={"span"}
                  className={classes.fontWeightBold}
                >
                  Confirmation&nbsp;number:&nbsp;
                </Typography>
                <Typography component={"span"}>
                  {appointment?.PatientID}
                </Typography>
              </Grid>
              <Grid wrap="wrap">
                <Typography
                  component={"span"}
                  className={classes.fontWeightBold}
                >
                  ZipCode:&nbsp;
                </Typography>
                {editZipCode == true ? (
                  <Input
                    style={{ width: "200px" }}
                    value={ZipCodeData}
                    autoFocus
                    onBlur={handleOnBlur}
                    onChange={(e) => {
                      console.log(e.target.value);
                      setZipCodeData(e.target.value);
                    }}
                  />
                ) : (
                  <Typography style={{ cursor: "pointer" }} component={"span"}>
                    {ZipCodeData || appointment?.ZipCode}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid item xs={5} container direction="column" wrap="nowrap">
              <Grid wrap="wrap">
                <Typography
                  component={"span"}
                  className={classes.fontWeightBold}
                >
                  Clinic:&nbsp;
                </Typography>
                <Typography component={"span"}>
                  {appointment?.ClinicName}
                </Typography>
              </Grid>
              <Grid wrap="wrap">
                <Typography
                  component={"span"}
                  className={classes.fontWeightBold}
                >
                  Provider:&nbsp;
                </Typography>
                <Typography component={"span"}>
                  {appointment?.SelectProvider}
                </Typography>
              </Grid>
              <Grid wrap="wrap">
                <Typography
                  component={"span"}
                  className={classes.fontWeightBold}
                >
                  Appointment&nbsp;Type:&nbsp;
                </Typography>
                <Typography component={"span"}>
                  {appointment?.AppointmentType}
                </Typography>
              </Grid>
              <Grid wrap="wrap">
                <Typography
                  component={"span"}
                  className={classes.fontWeightBold}
                >
                  Telehealth&nbsp;or&nbsp;In-person?&nbsp;
                </Typography>
                {appointment?.ClinicName.includes("PrEP Phone Call") ? (
                  <Typography component={"span"}>{"Phone Call"}</Typography>
                ) : appointment?.ClinicName.includes("MAB follow up") ? (
                  <Typography component={"span"}>{"Phone Call"}</Typography>
                ) : (
                  <Typography component={"span"}>
                    {appointment?.InPersonOrTelehealth}
                  </Typography>
                )}
              </Grid>
              <Grid wrap="wrap">
                <Typography
                  component={"span"}
                  className={classes.fontWeightBold}
                >
                  Language:&nbsp;
                </Typography>
                {appointment?.ClinicName.includes("PrEP Phone Call") ? (
                  <Typography component={"span"}>
                    {appointment?.InterpretationLanguage}
                  </Typography>
                ) : (
                  <Typography component={"span"}>{""}</Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : null}
      <iframe
        ref={formRef}
        id={iFrameId}
        title="Make an Appointment at BOI"
        // onload="window.parent.scrollTo(0,0)"
        onLoad={hideSpinner}
        allowtransparency="true"
        allowfullscreen="true"
        allow="geolocation; microphone; camera"
        src={src}
        frameborder="0"
        style={{
          minWidth: "100%",
          border: "none",
          marginLeft: "10px",
          height: "100%",
        }}
        scrolling="yes"
        hidden={loading}
      ></iframe>
    </>
  );
}
Form.propTypes = {};
export default Form;
