import React, { useEffect, useState } from "react";
import { Loader } from "svg-loader-react";
import {
  BlockApptsDelete,
  BlockApptsDisplay,
  BlockApptsPost,
} from "../../../../../apis";
import {
  FormControl,
  Grid,
  Button,
  Typography,
  IconButton,
  Box,
  Tab,
  Tabs,
  Tooltip,
  CircularProgress,
  FormHelperText,
} from "@material-ui/core";
import { Table, Spin, Space, Select } from "antd";
import PropTypes from "prop-types";
import { DateInput } from "../Common";
import { BlockDateInput } from "../Common";
import Interval from "../Interval/Interval";
import { Add, Delete, Save } from "@material-ui/icons";
import { CloseOutlined } from "@ant-design/icons";
import _ from "lodash";
import moment from "moment-timezone";
import "moment-timezone";
import "./index.css";

import { ToastContainer, toast } from "react-toastify";
import { red } from "@material-ui/core/colors";

const BlockOff = (props) => {
  console.log(props, "propsssssdata");
  console.log(props, "propsssssdata1");
  console.log(props, "propsssss");
  console.log(props.r_qID, "r_qID");
  console.log(props.selectedClinicData, "selectedClinicDataa");

  const [starPropsTime, setStartPropsTime] = useState();
  const [endPropsTime, setEndPropsTime] = useState();
  const [bloackedAppointments, setBloackedAppointments] = useState();
  const [show, setShow] = useState(false);
  const [useIndex, setUseIndex] = useState();
  const [deleteResponse, setDeleteResponse] = useState(false);
  const [updatedDateSlots, setUpdatedDateSlots] = useState([]);
  const [isSavedIconDisplay, setIsSavedIconDisplay] = useState("Save");
  const [isDeleteIconDisplay, setDeleteSavedIconDisplay] = useState("Delete");
  const [lastSavedIndex, setLastSavedIndex] = useState(null);
  const [wholeData, setWholeData] = useState([
    {
      date: new Date(),
      slot: "",
      isSaved: "false",
      isDeletedData: "false",
    },
  ]);
  const [timeSlots, settimeSlots] = useState([]);
  const [dummyArrayTimeSlot, setdummyArrayTimeSlot] = useState([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState([
    "Select Time Slot",
  ]);
  const [isSaving, setIsSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [value, setValue] = useState(0);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [blocktoast, setbloacktoast] = useState();
  const iconStyle = {
    color: "black",
    marginLeft: "-2px",
    marginTop: "6px",
    backgroundColor: "white",
    fontSize: "30px",
  };
  const closeDeletedData = (index) => {
    const updatedData = [...wholeData];
    updatedData.splice(index, 1);
    setWholeData(updatedData);
  };
  useEffect(() => {
    //Intial props data
    let timeString = props.intervals[0];
    let convertedTime = timeString.substr(0, 11);
    let mngStartTime = timeString.substr(0, 5);
    let convertedTimed1 = timeString.substr(5, 5);
    let intialStartTime = mngStartTime
      .split(":")
      .map((part) => part.padStart(2, "0"))
      .join(":")
      .trim();
    console.log(intialStartTime.trim(), "intialStartTime"); // Output: 09:00

    let intialStartTime1 = convertedTimed1
      .split(":")
      .map((part) => part.padStart(2, "0"))
      .join(":")
      .trim();
    console.log(convertedTime, "intialStartTime1", intialStartTime1); // Output: 11:00
    let time1 = intialStartTime;
    let time2 = intialStartTime1;
    // Convert time1
    let morningStartedTime = new Date(
      "2000-01-01T" + time1 + ":00"
    ).toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" });
    console.log(morningStartedTime, "morningStartedTime"); // Output: 09:00 AM

    // Convert time2
    let afternoonEndedTime = new Date(
      "2000-01-01T" + time2 + ":00"
    ).toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" });
    console.log(afternoonEndedTime, "afternoonEndedTime"); // Output: 11:40 AM0

    let mngEndedTime = time2;

    // Afternoon start and end date Formate

    let timeString14 = props.intervals[1] ? props.intervals[1] : "";
    console.log(
      timeString14,
      "timeString14",
      props.intervals[1],
      props.intervals
    );
    let startTimeFormate1 =
      timeString14 !== "" ? timeString14.substr(0, 5) : "";
    let convertedTimed2 = timeString14 !== "" ? timeString14.substr(5, 6) : "";
    let intialStartTime12 = startTimeFormate1
      .split(":")
      .map((part) => part.padStart(2, "0"))
      .join(":");
    console.log(intialStartTime12, "intialStartTime12"); // Output: 01:00

    let intialStartTime2 = convertedTimed2
      .split(":")
      .map((part) => part.padStart(1, "0"))
      .join(":");
    console.log(intialStartTime2, "intialStartTime2"); // Output: 03:00
    let time21 = intialStartTime12;
    let time3 = intialStartTime2;
    const starteAvaliabilityTime = formatTime(time21);
    console.log(starteAvaliabilityTime, "starteAvaliabilityTime");
    function formatTime(time) {
      const [hours, minutes] = time.split(":");
      let period = "AM";
      let formattedHours = parseInt(hours);

      if (formattedHours >= 12) {
        period = "PM";
        formattedHours = formattedHours === 12 ? 12 : formattedHours - 12;
      }

      return `${formattedHours
        .toString()
        .padStart(2, "0")}:${minutes} ${period}`;
    }
    const endAvaliabilityTime = formatTime1(time3);
    console.log(endAvaliabilityTime, "endAvaliabilityTime");
    function formatTime1(time) {
      const [hours, minutes] = time.split(":");
      let period = "AM";
      let formattedHours = parseInt(hours);

      if (formattedHours >= 12) {
        period = "PM";
        formattedHours = formattedHours === 12 ? 12 : formattedHours - 12;
      }
      return `${formattedHours
        .toString()
        .padStart(2, "0")}:${minutes} ${period}`;
    }
    function splitTimeUntil(startTime, endTime, slotDuration) {
      const slots = [];
      const format = "hh:mm A"; // Updated format string
      const start = moment(startTime, format);
      const end = moment(endTime, format);
      while (start.isBefore(end)) {
        slots.push(start.format(format));
        start.add(slotDuration, "minutes");
      }

      return slots;
    }
    let startDataFormate1 = morningStartedTime;
    console.log(startDataFormate1, "startDataFormate1");
    console.log(afternoonEndedTime, mngEndedTime, "afternoonEndedTime");
    let data = splitTimeUntil(
      morningStartedTime,
      mngEndedTime,
      props.slotDuration
    );
    function formatTimeSlot(date) {
      let hours = date.getHours();
      let minutes = date.getMinutes();
      let ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      let strTime = hours + ":" + minutes + " " + ampm;
      console.log(strTime, "dateYuva");
      return strTime;
    }
    function parseTime(timeStr) {
      const [time, modifier] = timeStr.split(" ");
      let [hours, minutes] = time.split(":").map(Number);

      if (modifier === "PM" && hours !== 12) {
        hours += 12;
      }
      if (modifier === "AM" && hours === 12) {
        hours = 0;
      }

      return hours * 60 + minutes;
    }

    function isTimeLessThan(time1, time2) {
      const minutes1 = parseTime(time1);
      const minutes2 = parseTime(time2);

      return minutes1 < minutes2;
    }
    let data1 = splitTimeUntil(
      starteAvaliabilityTime,
      endAvaliabilityTime,
      props.slotDuration
    );
    data.concat(data1);
    console.log(data, "data");
    let temp1 = data.concat(data1);
    let temp2 = [];
    let temp3 = [];
    console.log(temp1, "temp1");
    console.log(new Date());
    temp1.map((item, index) => {
      // console.log(formatTimeSlot(currentTime), "uniqueiden");
      console.log(new Date(), "yuva");
      let currentTime = formatTimeSlot(new Date());
      console.log(isTimeLessThan(currentTime, item), "yuva");
      temp3.push({ label: String(item), value: item });
      if (isTimeLessThan(currentTime, item)) {
        temp2.push({ label: String(item), value: item });
      }
    });
    settimeSlots([...temp3]);
    setdummyArrayTimeSlot([...temp2]);
    console.log(data, temp2, "unique");
  }, [props]);
  useEffect(() => {
    console.log("timeslots useeffect");
  }, [props]);
  useEffect(() => {}, [starPropsTime, endPropsTime]);

  //**************** POST API ******************//
  const blockedSavedData = async (e, index) => {
    setUseIndex(index);
    console.log(index, "indexData");
    console.log(timeSlots[e], "timeslots");

    if (selectedTimeSlot.length !== 0) {
      e.preventDefault();

      setIsSaveLoading(true);
      setIsError(false);
      setIsSaving(true);

      // Display the "Blocking..." toast immediately
      const blockingToastId = toast("Blocking...", {
        position: "bottom-right",
        autoClose: false, // Set autoClose to false to keep it visible until we update it
        hideProgressBar: true,
        closeOnClick: true,
        progress: undefined,
        draggable: true,
        theme: "dark",
      });

      let selectedClinicData = props.selectedClinicData;
      let date =
        selectedDate._d === undefined ? new Date() : new Date(selectedDate._d);
      console.log(date, "date selected");

      let year = date.getFullYear();
      let month = (date.getMonth() + 1).toString().padStart(2, "0");
      let getdate = date.getDate().toString().padStart(2, "0");
      console.log(getdate, "row");

      const r_qID = props.r_qID;
      console.log(r_qID, "r_qID88");

      let selectedDate1 = `${year}-${month}-${getdate} ${selectedTimeSlot[0]}`;
      console.log(selectedDate1, "dateee");

      try {
        const filterUpdatedClinic = await BlockApptsPost(
          "PEG",
          "BLOCK",
          updatedDateSlots[index],
          selectedClinicData,
          props.maxAppSlot,
          props.advanceApp,
          props.r_qID
        );

        console.log("apiResponsemessage", filterUpdatedClinic);

        if (filterUpdatedClinic.statusCode === 200) {
          setIsSaveLoading(false);
          setIsError(false);
          setIsSavedIconDisplay("Blocked");
          setWholeData((prevData) =>
            prevData.map((item, idx) =>
              idx === index ? { ...item, isSaved: "true" } : item
            )
          );
          setLastSavedIndex(index);

          // Update the toast with the response message
          toast.update(blockingToastId, {
            render: filterUpdatedClinic.message.message,
            type: toast.TYPE.SUCCESS,
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            progress: undefined,
            draggable: true,
            theme: "dark",
          });

          setIsSaving(false);
        } else if (filterUpdatedClinic.statusCode === 500) {
          setIsSaveLoading(false);
          setIsError(false);
          setIsSavedIconDisplay("Failed");
          setWholeData((prevData) =>
            prevData.map((item, idx) =>
              idx === index ? { ...item, isSaved: "true" } : item
            )
          );
          setLastSavedIndex(index);
          throw new Error(filterUpdatedClinic.error.response.data.message);
        }
      } catch (error) {
        // Update the toast with the error message
        toast.update(blockingToastId, {
          render: error.message,
          type: toast.TYPE.ERROR,
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          progress: undefined,
          draggable: true,
          theme: "dark",
        });

        setWholeData((prevData) =>
          prevData.map((item, idx) =>
            idx === index ? { ...item, isSaved: "error" } : item
          )
        );
      }
    } else {
      setWholeData((prevData) =>
        prevData.map((item, idx) =>
          idx === index ? { ...item, isSaved: "false" } : item
        )
      );
    }
  };

  //DELETE API
  const blockedDeletedData = async (e, index) => {
    let dateData = new Date(wholeData[index].date);
    let yearData = dateData.getFullYear();
    let month = dateData.getMonth() + 1;
    let dayData = dateData.getDate().toString().padStart(2, "0");

    let deleteItem =
      yearData +
      "-" +
      month.toString().padStart(2, "0") +
      "-" +
      dayData +
      " " +
      wholeData[index].slot;
    console.log(wholeData[index], "delete", deleteItem);

    setUseIndex(index);
    if (selectedTimeSlot.length !== 0) {
      setIsError(false);
      setIsLoading(true);
      setIsSaving(false);

      // Display the "Unblocking..." toast immediately
      const unblockingToastId = toast("Unblocking...", {
        position: "bottom-right",
        autoClose: false, // Keep the toast visible until we update it
        hideProgressBar: true,
        closeOnClick: true,
        progress: undefined,
        draggable: true,
        theme: "dark",
      });

      let maxSlotsPerAppt = props.maxAppSlot;
      let rollingDays = props.advanceApp;
      let selectedClinicData = props.selectedClinicData;
      e.preventDefault();

      try {
        const BlockApptsDisplayDeletedd = await BlockApptsDelete(
          "PEG",
          "BLOCK",
          deleteItem,
          selectedClinicData,
          maxSlotsPerAppt,
          rollingDays,
          props.r_qID
        );

        console.log(BlockApptsDisplayDeletedd, "BlockApptsDisplayDeletedd");

        if (BlockApptsDisplayDeletedd.statusCode === 200) {
          setIsLoading(false);
          setDeleteSavedIconDisplay("Unblock");
          setWholeData((prevData) =>
            prevData.map((item, idx) =>
              idx === index ? { ...item, isDeletedData: "true" } : item
            )
          );
          setLastSavedIndex(index);

          // Update the toast with the success message
          toast.update(unblockingToastId, {
            render: BlockApptsDisplayDeletedd.message.message,
            type: toast.TYPE.SUCCESS,
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            progress: undefined,
            draggable: true,
            theme: "dark",
          });

          setIsSaving(false);
        } else {
          throw new Error(
            BlockApptsDisplayDeletedd?.error?.response?.data?.message || "Error"
          );
        }
      } catch (error) {
        setIsLoading(false);
        setIsSaving(false);

        // Update the toast with the error message
        toast.update(unblockingToastId, {
          render: error.message,
          type: toast.TYPE.ERROR,
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          progress: undefined,
          draggable: true,
          theme: "dark",
        });

        setWholeData((prevData) =>
          prevData.map((item, idx) =>
            idx === index ? { ...item, isDeletedData: "error" } : item
          )
        );
      }
    } else {
      setWholeData((prevData) =>
        prevData.map((item, idx) =>
          idx === index ? { ...item, isDeletedData: "false" } : item
        )
      );
    }
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  useEffect(() => {}, [wholeData]);
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  //GET API
  const viewBlockedAppts = () => {
    async function fun() {
      setLoading(true);
      try {
        let GetApiBlocked = await BlockApptsDisplay(
          "PEG",
          "BLOCK",
          "2029-06-08 09:00 AM",
          props.selectedClinicData,
          props.maxAppSlot,
          props.advanceApp,
          props.r_qID
        );

        setBloackedAppointments(JSON.parse(GetApiBlocked));
        console.log(GetApiBlocked, "GetApiBlocked");
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
    fun();
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log(newValue, "value", event, "event value");
  };
  var [count, setCount] = useState(1);
  const columns = [
    {
      title: "Dates",
      dataIndex: "date",
      key: "date",
      render: (item, recorder) => {
        return item;
      },
    },
    {
      title: "Blocked Time",
      dataIndex: "time",
      key: "time",
      render: (item, recorder) => {
        return item;
      },
    },
  ];
  const handleDateInput = () => {
    setIsDeleted(true);
    console.log(wholeData, "wholeData");
    const items = [...wholeData];
    const slot = {
      date: new Date(),
      slot: "",
      isSaved: "false",
    };
    setWholeData([...items, slot]);
  };
  const handleCheckbox = (value, index) => {
    setIsError(false);
    const recieveData = wholeData[index].date;
    const recieveYear = recieveData.getFullYear();
    const recieveMonth = String(recieveData.getMonth() + 1).padStart(2, "0");
    const recieveDay = String(recieveData.getDate()).padStart(2, "0");
    const formattedDate = `${recieveYear}-${recieveMonth}-${recieveDay}`;
    console.log(formattedDate, "date formatted");
    const allData = formattedDate + " ".concat(value);
    setUpdatedDateSlots([...updatedDateSlots, allData]);
    console.log(allData, "formattedDate");
    const item = [...wholeData];
    item[index].slot = value;
    setSelectedTimeSlot(value);
    setWholeData([...item]);
    console.log(item, "item");
  };
  const handleDateChange = (value, index) => {
    console.log(value._d.getDate(), index, "yuvas");
    const slots = [...wholeData];
    slots[index].date = value._d;
    let filterTimeSlots = [];
    // let dummyTimeSlot=[];
    function formatTimeSlot(date) {
      let hours = date.getHours();
      let minutes = date.getMinutes();
      let ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      let strTime = hours + ":" + minutes + " " + ampm;
      console.log(strTime, "dateYuva");
      return strTime;
    }
    function parseTime(timeStr) {
      const [time, modifier] = timeStr.split(" ");
      let [hours, minutes] = time.split(":").map(Number);

      if (modifier === "PM" && hours !== 12) {
        hours += 12;
      }
      if (modifier === "AM" && hours === 12) {
        hours = 0;
      }

      return hours * 60 + minutes;
    }

    function isTimeLessThan(time1, time2) {
      const minutes1 = parseTime(time1);
      const minutes2 = parseTime(time2);

      return minutes1 < minutes2;
    }
    if (new Date().getDate() != value._d.getDate()) {
      console.log(timeSlots);
      setdummyArrayTimeSlot(timeSlots);
    } else {
      timeSlots.map((item, index) => {
        // console.log(formatTimeSlot(currentTime), "uniqueiden");
        let currentTime = formatTimeSlot(new Date());
        console.log(
          isTimeLessThan(currentTime, item.value),
          "yuva",
          new Date().getDate() != value._d.getDate()
        );

        if (isTimeLessThan(currentTime, item.value)) {
          filterTimeSlots.push({
            label: String(item.value),
            value: item.value,
          });
        }
      });
      setdummyArrayTimeSlot(filterTimeSlots);
    }

    console.log(filterTimeSlots, "filterTimeSlots");
    setWholeData([...slots]);

    console.log(timeSlots, "check");
    console.log(wholeData, "setWholeData");
  };
  useEffect(() => {}, [
    count,
    selectedDate,
    blocktoast,
    deleteResponse,
    dummyArrayTimeSlot,
  ]);
  var renderItems = () => {
    return wholeData.map((item, index) => (
      <div key={index}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm md={4}>
              <Typography component="label">
                Please select date to block / unblock
              </Typography>
            </Grid>
            <Grid item xs={12} sm md={4}>
              <Typography component="label">
                Please select time to block / unblock
              </Typography>
            </Grid>
          </Grid>
          <Grid item container justifyContent="center" spacing={3} xs md={12}>
            <Grid item xs={12} sm md={4}>
              <BlockDateInput
                label="Datess"
                value={item.date}
                onChange={(value) => {
                  console.log(value, "value");
                  handleDateChange(value, index);
                }}
              />
            </Grid>
            <Grid item xs={12} sm md={4} spacing={3}>
              <FormControl
                fullWidth
                variant="outlined"
                size="small"
                error={isError}
              >
                <Select
                  className="dropDownSelection"
                  placeholder="Select an option"
                  fullWidth
                  color="primary"
                  variant="outlined"
                  label="Time Slots"
                  defaultValue="Select Time Slot"
                  style={{ width: "100%", placeholder: "Helko" }}
                  value={wholeData[index].slot}
                  onChange={(e) => handleCheckbox(e, index)}
                  options={[...dummyArrayTimeSlot]}
                />
                {isError && (
                  <FormHelperText>Please select a time slot.</FormHelperText>
                )}
              </FormControl>
            </Grid>
            {/* Save */}
            <Grid item xs={12} sm md={2}>
              {isSaveLoading && index === useIndex ? (
                <CircularProgress
                  size={20}
                  style={{
                    textAlign: "center",
                    marginTop: "10px",
                    marginLeft: "40px",
                  }}
                />
              ) : item.isSaved == "error" || item.isDeletedData == "error" ? (
                <Button
                  style={{
                    justifyContent: "space-center",
                    textAlign: "center",
                    cursor:
                      item.isDeletedData == "true" ||
                      (item.isSaved == "error" &&
                        (index === lastSavedIndex ||
                          index >= wholeData.length - 100))
                        ? "not-allowed"
                        : "pointer",
                    width: "120px",
                    backgroundColor:
                      item.isSaved == "true" ||
                      (item.isDeletedData == "true" &&
                        (index === lastSavedIndex ||
                          index >= wholeData.length - 100))
                        ? "#00aae7"
                        : null,
                  }}
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={
                    item.isSaved == "error" ||
                    (item.isDeletedData == "error" &&
                      (index === lastSavedIndex ||
                        index >= wholeData.length - 100))
                      ? null
                      : (e) => blockedSavedData(e, index)
                  }
                  disabled={!wholeData[index].slot} // Disable if slot is not selected
                >
                  {item.isSaved == "true" ||
                  (item.isDeletedData === "true" &&
                    (index === lastSavedIndex ||
                      index >= wholeData.length - 100))
                    ? "Blocked"
                    : item.isSaved == "error"
                    ? "Failed"
                    : item.isDeletedData === "error"
                    ? "unblock Failed"
                    : "Save"}
                </Button>
              ) : item.isDeletedData == "true" ? (
                <Button
                  style={{
                    justifyContent: "space-center",
                    cursor:
                      item.isDeletedData == "true" &&
                      (index === lastSavedIndex ||
                        index >= wholeData.length - 100)
                        ? "not-allowed"
                        : "pointer",
                    width: "120px",
                    backgroundColor:
                      item.isDeletedData == "true" &&
                      (index === lastSavedIndex ||
                        index >= wholeData.length - 100)
                        ? "#00aae7"
                        : null,
                  }}
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={
                    item.isDeletedData == "true" &&
                    (index === lastSavedIndex ||
                      index >= wholeData.length - 100)
                      ? null
                      : (e) => blockedSavedData(e, index)
                  }
                  disabled={!wholeData[index].slot} // Disable if slot is not selected
                >
                  {item.isDeletedData == "true" &&
                  (index === lastSavedIndex || index >= wholeData.length - 100)
                    ? "Unblocked"
                    : item.isSaved == "error"
                    ? "Unblock Failed"
                    : "Save"}
                </Button>
              ) : (
                <Button
                  style={{
                    justifyContent: "space-center",
                    cursor:
                      item.isSaved == "true" &&
                      (index === lastSavedIndex ||
                        index >= wholeData.length - 100)
                        ? "not-allowed"
                        : "pointer",
                    width: "120px",
                    backgroundColor:
                      item.isSaved == "true" &&
                      (index === lastSavedIndex ||
                        index >= wholeData.length - 100)
                        ? "#00aae7"
                        : null,
                  }}
                  startIcon={<Save style={{ width: "18px" }} />}
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={
                    item.isSaved == "true" &&
                    (index === lastSavedIndex ||
                      index >= wholeData.length - 100)
                      ? null
                      : (e) => blockedSavedData(e, index)
                  }
                  disabled={!wholeData[index].slot} // Disable if slot is not selected
                >
                  {item.isSaved == "true" &&
                  (index === lastSavedIndex ||
                    index >= wholeData.length - 100) ? (
                    "Blocked"
                  ) : item.isSaved == "error" ? (
                    "Failed"
                  ) : (
                    <span style={{ fontSize: "15px", fontWeight: "500" }}>
                      Save
                    </span>
                  )}
                </Button>
              )}
            </Grid>
            {/* Delete */}
            <Grid item xs={12} sm md={1}>
              <Tooltip
                placement="bottom"
                title="Unblock appointment"
                arrow
                style={{ backgroundColor: "transparent" }}
              >
                <IconButton
                  style={{
                    backgroundColor: "transparent",
                    cursor:
                      item.isDeletedData == "true" ||
                      (item.isSaved == "error" &&
                        (index === lastSavedIndex ||
                          index >= wholeData.length - 100))
                        ? "not-allowed"
                        : "pointer",
                  }}
                  edge="end"
                  aria-label="delete"
                  onClick={
                    (item.isDeletedData == "true" &&
                      (index === lastSavedIndex ||
                        index >= wholeData.length - 100)) ||
                    item.isSaved == "error"
                      ? null
                      : (e) => blockedDeletedData(e, index)
                  }
                  color="secondary"
                  size="small"
                  disabled={!wholeData[index].slot} // Disable if slot is not selected
                >
                  {isLoading && index === useIndex ? (
                    <CircularProgress
                      size={20}
                      style={{
                        backgroundColor: "transparent",
                        textAlign: "center",
                        marginLeft: "10px",
                        marginTop: "10px",
                      }}
                    />
                  ) : (
                    <Delete
                      style={{
                        marginLeft: "6px",
                        marginBottom: "10px",
                        fontSize: "41px",
                      }}
                    ></Delete>
                  )}
                </IconButton>
              </Tooltip>
            </Grid>
            {/* Close */}
            <Grid item xs={12} sm md={1}>
              <Tooltip
                placement="bottom"
                title="Hide Row"
                arrow
                style={{ backgroundColor: "transparent" }}
              >
                <IconButton
                  edge="end"
                  aria-label="close"
                  color=""
                  size="small"
                  onClick={() => closeDeletedData(index)}
                >
                  <CloseOutlined style={iconStyle} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </div>
    ));
  };
  useEffect(() => {
    // console.log(selectedDate, "selected date");
  }, [show]);

  const getBlockedAppointments = async () => {
    if (show == true) {
      setShow(false);
    } else {
      setShow(true);
    }
  };
  return (
    <>
      <Grid item xs={12}>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          xs={12}
        >
          <Button
            onClick={() => {
              getBlockedAppointments();
            }}
            variant="contained"
            color="primary"
            style={{ marginTop: "10px" }}
          >
            Block Appointments
          </Button>
        </Grid>
        <Grid>
          {show === true ? (
            <>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tooltip
                      placement="bottom"
                      title="Block Appointments"
                      arrow
                    >
                      <Tab
                        label="Block / Unblock Appointments"
                        {...a11yProps(0)}
                      ></Tab>
                    </Tooltip>
                    <Tooltip
                      placement="bottom"
                      title="View Blocked Appointments"
                      arrow
                    >
                      <Tab
                        label="View Blocked Appointments"
                        {...a11yProps(1)}
                        onClick={viewBlockedAppts}
                      />
                    </Tooltip>
                  </Tabs>
                </Box>
                {value == 0 ? (
                  <>
                    {" "}
                    <TabPanel value={value} index={0}>
                      <Grid>{renderItems()}</Grid>
                      <Grid item container justifyContent="flex-end">
                        <Button
                          color="primary"
                          aria-label="delete"
                          startIcon={<Add />}
                          onClick={handleDateInput}
                        >
                          New Blocks
                        </Button>
                      </Grid>
                    </TabPanel>
                  </>
                ) : (
                  <TabPanel value={value} index={1}>
                    {loading ? (
                      <div className="loader">
                        <Loader name="threedots" color="#00BEB5" />
                        <br />
                      </div>
                    ) : (
                      <Table
                        dataSource={bloackedAppointments}
                        columns={columns}
                      />
                    )}
                    ;
                  </TabPanel>
                )}
              </Box>
            </>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
};
export default BlockOff;
