import React, { useEffect, useState, createRef, useRef } from "react";
import PropTypes from "prop-types";
import { Button, Input, IconButton } from "antd";

import { getDimensions } from "components/Calendar/utils";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
// import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { updateAppointmentsData } from "apis";
import { EditTwoTone } from "@ant-design/icons";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    borderLeftWidth: 4,
    borderLeftStyle: "solid",
    borderLeftColor: ({ Cancel }) =>
      Cancel ? "#979797" : theme.palette.primary.main,
    paddingLeft: theme.spacing(1),
    "& .MuiTypography-body1": {
      color: ({ Cancel }) => (Cancel ? "#606060" : "inherit"),
    },
  },
  button: {},
  leftContainer: {
    paddingRight: theme.spacing(2),
  },
  typography: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  fontWeightBold: {
    fontWeight: "bold",
  },
  buttonStyle: {
    width: "100%",
    padding: "0.5rem",
    color: "#ffffff",
  },
  pin: {
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
  },
}));

function Form({ src, title, iFrameId, appointment }) {
  const {
    AppointmentID,
    FirstName,
    LastName,
    Phone,
    Email,
    ClinicName,
    SelectProvider,
    PatientEMRNumber,
    AppointmentType,
    TeleHealthURL,
    InPersonOrTelehealth,
    AppIdHash,
    AppointmentStartTime,
    AppointmentEndTime,
    Cancel,
    PatientID,
    ZipCode,
    InterpretationLanguage,
  } = appointment;
  const classes = useStyles({ Cancel });
  const formRef = createRef();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [loading, setLoading] = useState(true);
  const weekGridCellRef = useRef(null);
  const [PhoneNumber, setPhoneNumber] = useState(Phone);
  const [editPhone, setEditPhone] = useState(false);
  const [EmailId, setEmailId] = useState(Email);
  const [editEmailId, setEditEmailId] = useState(false);
  const [ZipCodeData, setZipCodeData] = useState(ZipCode);
  const [editZipCode, setEditZipCode] = useState(false);
  const [FirstNameValue, setFirstNameValue] = useState(FirstName);
  const [LastNameValue, setLastNameValue] = useState(LastName);
  const phoneInputRef = useRef(null);
  // const editPhoneFun = () => {
  //   setEditPhone(true);
  // };
  // const emailEditFun = () => {
  //   setEditEmailId(true);
  // };
  // const zipCodeFun = () => {
  //   setEditZipCode(true);
  // };
  const handleOnBlur = () => {
    console.log("blurtriggered");
    // setEditPhone(false);
    // setEditEmailId(false);
    // setEditZipCode(false);
  };
  const hideSpinner = () => {
    setLoading(false);
  };
  useEffect(() => {
    if (!Cancel || !weekGridCellRef.current) return;

    const currentWeekCell = weekGridCellRef.current;
    const parentContainerCell = currentWeekCell.parentElement.parentElement;
    parentContainerCell.classList.add("cancel");
  }, [weekGridCellRef, Cancel]);
  console.log(
    InterpretationLanguage,
    weekGridCellRef,
    "InterpretationLanguage"
  );
  const startTime = AppointmentStartTime;
  const startApptTime = startTime.split(" ").slice(-2).join(" ");
  console.log(startApptTime, "startApptTime");
  useEffect(() => {
    const { width, height } = getDimensions(formRef.current);
    setDimensions({ width, height });
    var ifr = document.getElementById(iFrameId);
    if (window.location.href && window.location.href.indexOf("?") > -1) {
      var get = window.location.href.substr(
        window.location.href.indexOf("?") + 1
      );
      if (ifr && get.length > 0) {
        var src = ifr.src;
        src = src.indexOf("?") > -1 ? src + "&" + get : src + "?" + get;
        ifr.src = src;
      }
    }
    window.handleIFrameMessage = function (e) {
      if (typeof e.data === "object") {
        return;
      }
      var args = e.data.split(":");
      var iframe;
      args[2] = iFrameId.split("-")[1];
      if (args.length > 2) {
        iframe = document.getElementById(
          "JotFormIFrame-" + args[args.length - 1]
        );
      } else {
        iframe = document.getElementById("JotFormIFrame");
      }
      if (!iframe) {
        return;
      }
      switch (args[0]) {
        case "scrollIntoView":
          iframe.scrollIntoView();
          break;
        case "setHeight":
          iframe.style.height = args[1] + "px";
          break;
        case "collapseErrorPage":
          if (iframe.clientHeight > window.innerHeight) {
            iframe.style.height = window.innerHeight + "px";
          }
          break;
        case "reloadPage":
          window.location.reload();
          break;
        case "loadScript":
          var src = args[1];
          if (args.length > 3) {
            src = args[1] + ":" + args[2];
          }
          var script = document.createElement("script");
          script.src = src;
          script.type = "text/javascript";
          document.body.appendChild(script);
          break;
        case "exitFullscreen":
          if (window.document.exitFullscreen) window.document.exitFullscreen();
          else if (window.document.mozCancelFullScreen)
            window.document.mozCancelFullScreen();
          else if (window.document.mozCancelFullscreen)
            window.document.mozCancelFullScreen();
          else if (window.document.webkitExitFullscreen)
            window.document.webkitExitFullscreen();
          else if (window.document.msExitFullscreen)
            window.document.msExitFullscreen();
          break;
      }
      var isJotForm = e.origin.indexOf("jotform") > -1 ? true : false;
      if (
        isJotForm &&
        "contentWindow" in iframe &&
        "postMessage" in iframe.contentWindow
      ) {
        var urls = {
          docurl: encodeURIComponent(document.URL),
          referrer: encodeURIComponent(document.referrer),
        };
        iframe.contentWindow.postMessage(
          JSON.stringify({ type: "urls", value: urls }),
          "*"
        );
      }
    };
    if (window.addEventListener) {
      window.addEventListener("message", window.handleIFrameMessage, false);
    } else if (window.attachEvent) {
      window.attachEvent("onmessage", window.handleIFrameMessage);
    }
  }, []);
  const editEnable = () => {
    console.log(AppointmentID, "AppointmentID???");
    setEditPhone(true);
    setEditEmailId(true);
    setEditZipCode(true);
  };
  const updateAppointmentDetails = async () => {
    console.log(
      AppointmentID,
      "AppointmentID???",
      PhoneNumber,
      EmailId,
      ZipCodeData,
      FirstName,
      LastName
    );
    let updatedDetails = {
      AppointmentID: AppointmentID,
      FirstName: FirstNameValue,
      LastName: LastNameValue,
      Email: EmailId,
      Phone: PhoneNumber,
      ZipCode: ZipCodeData,
    };
    console.log(updatedDetails, " ");
    try {
      await updateAppointmentsData(updatedDetails);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (editPhone && phoneInputRef.current) {
      phoneInputRef.current.focus();
    }
  }, [editPhone]);
  return (
    <>
      {loading ? (
        <div
          style={{
            position: "absolute",
            top: "65%",
            left: "50%",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress color="primary" size={50} />
        </div>
      ) : null}
      <Grid container direction="row" alignItems="center">
        <Grid item>
          <Typography
            style={{ margin: "10px", marginLeft: "107px" }}
            className={`${classes.typography} ${classes.fontWeightBold}`}
          >
            Patient Details
          </Typography>
        </Grid>
        <Grid item xs={2} style={{ display: "flex", gap: "16px" }}>
          <Button onClick={editEnable}>Edit</Button>
          <Button onClick={updateAppointmentDetails}>Update</Button>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center" // Center content horizontally
        ref={weekGridCellRef}
        style={{
          display: "flex",
          flex: 1,
          alignContent: "center", // This centers content within each flex item
          alignItems: "center", // Center content vertically
          width: "78%",
          padding: "10px",
          margin: "0 auto", // Center the grid itself within its parent
          marginBottom: "40px",
          boxShadow: "0 0 8px 2px rgba(0, 0, 0, 0.15)",
          // boxSizing: "border-box",
          position: "relative",
        }}
      >
        <Grid container wrap="wrap" item xs={2}>
          <Grid item>
            <Typography className={classes.typography}>
              {startApptTime}
              {" - "}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.typography}>
              {AppointmentEndTime}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          className={`${classes.leftContainer} ${classes.root}`}
          item
          xs={5}
          container
          direction="column"
          // wrap="nowrap"
        >
          {editPhone == true ? (
            <div style={{ display: "flex", gap: "16px", marginBottom: "8px" }}>
              <Input
                ref={phoneInputRef}
                disabled={!editPhone}
                value={FirstNameValue}
                onBlur={handleOnBlur}
                onChange={(e) => {
                  console.log(e.target.value);
                  setFirstNameValue(e.target.value);
                }}
              />
              <Input
                value={LastNameValue}
                autoFocus
                onBlur={handleOnBlur}
                onChange={(e) => {
                  console.log(e.target.value);
                  setLastNameValue(e.target.value);
                }}
              />
            </div>
          ) : (
            <Typography
              className={`${classes.typography} ${classes.fontWeightBold}`}
            >
              {`${FirstName} ${LastName}`}
            </Typography>
          )}

          {editPhone == true ? (
            <Input
              style={{ marginBottom: "8px" }}
              value={PhoneNumber}
              autoFocus
              onBlur={handleOnBlur}
              onChange={(e) => {
                console.log(e.target.value);
                setPhoneNumber(e.target.value);
              }}
            />
          ) : (
            <Typography
              style={{ cursor: "pointer" }}
              className={classes.typography}
            >
              {Phone}
            </Typography>
          )}
          {editEmailId == true ? (
            <Input
              style={{ marginBottom: "8px" }}
              value={EmailId}
              autoFocus
              onBlur={handleOnBlur}
              onChange={(e) => {
                console.log(e.target.value);
                setEmailId(e.target.value);
              }}
            />
          ) : (
            <Typography
              style={{ cursor: "pointer" }}
              className={classes.typography}
            >
              {Email}
            </Typography>
          )}
          <Grid wrap="wrap">
            <Typography component={"span"} className={classes.fontWeightBold}>
              EMR&nbsp;ID:&nbsp;
            </Typography>
            <Typography component={"span"}>{PatientEMRNumber}</Typography>
          </Grid>
          <Grid wrap="wrap">
            <Typography component={"span"} className={classes.fontWeightBold}>
              Confirmation&nbsp;number:&nbsp;
            </Typography>
            <Typography component={"span"}>{PatientID}</Typography>
          </Grid>
          <Grid wrap="wrap">
            <Typography component={"span"} className={classes.fontWeightBold}>
              ZipCode:&nbsp;
            </Typography>
            {editZipCode == true ? (
              <Input
                style={{ width: "200px" }}
                value={ZipCodeData}
                autoFocus
                onBlur={handleOnBlur}
                onChange={(e) => {
                  console.log(e.target.value);
                  setZipCodeData(e.target.value);
                }}
              />
            ) : (
              <Typography style={{ cursor: "pointer" }} component={"span"}>
                {ZipCode}
              </Typography>
            )}
          </Grid>
        </Grid>

        <Grid item xs={5} container direction="column" wrap="nowrap">
          <Grid wrap="wrap">
            <Typography component={"span"} className={classes.fontWeightBold}>
              Clinic:&nbsp;
            </Typography>
            <Typography component={"span"}>{ClinicName}</Typography>
          </Grid>
          <Grid wrap="wrap">
            <Typography component={"span"} className={classes.fontWeightBold}>
              Provider:&nbsp;
            </Typography>
            <Typography component={"span"}>{SelectProvider}</Typography>
          </Grid>
          <Grid wrap="wrap">
            <Typography component={"span"} className={classes.fontWeightBold}>
              Appointment&nbsp;Type:&nbsp;
            </Typography>
            <Typography component={"span"}>{AppointmentType}</Typography>
          </Grid>
          <Grid wrap="wrap">
            <Typography component={"span"} className={classes.fontWeightBold}>
              Telehealth&nbsp;or&nbsp;In-person?&nbsp;
            </Typography>
            {ClinicName.includes("PrEP Phone Call") ? (
              <Typography component={"span"}>{"Phone Call"}</Typography>
            ) : ClinicName.includes("MAB follow up") ? (
              <Typography component={"span"}>{"Phone Call"}</Typography>
            ) : (
              <Typography component={"span"}>{InPersonOrTelehealth}</Typography>
            )}
          </Grid>
          <Grid wrap="wrap">
            <Typography component={"span"} className={classes.fontWeightBold}>
              Language:&nbsp;
            </Typography>
            {ClinicName.includes("PrEP Phone Call") ? (
              <Typography component={"span"}>
                {InterpretationLanguage}
              </Typography>
            ) : (
              <Typography component={"span"}>{""}</Typography>
            )}
          </Grid>
        </Grid>
      </Grid>

      <iframe
        ref={formRef}
        id={iFrameId}
        title="Make an Appointment at BOI"
        // onload="window.parent.scrollTo(0,0)"
        onLoad={hideSpinner}
        allowtransparency="true"
        allowfullscreen="true"
        allow="geolocation; microphone; camera"
        src={src}
        frameborder="0"
        style={{
          minWidth: "100%",
          border: "none",
          marginLeft: "10px",
          height: "100%",
        }}
        scrolling="yes"
        hidden={loading}
      ></iframe>
    </>
  );
}

Form.propTypes = {};

export default Form;
